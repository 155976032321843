export const REPs = [
    {
        providerId: '230',
        providerName: 'CleanSky Energy',
        host: true
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '191',
        providerName: 'Discount Power'
    }
];

export const API_KEY = '6e87f7954677657ef652d40865dada9a';
export const API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';