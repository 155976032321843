import { PureComponent } from "react";
import {
    ChartTooltips, ChartWrapper, ViewMoreBtnRow, ChartFooter
} from "./styles/common.styled";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Tooltip, ResponsiveContainer, ReferenceLine
} from 'recharts';
import { ChartDataTypes } from "../types";
import { REPs } from "../config";
import { Button } from "@mui/material";
import moment from "moment";

const tooltipStyles = {
    cursor: {
        fill: 'transparent'
    },
}

const hostRep = REPs.filter(val => val.host === true)[0];
// const ww = window.innerWidth;

export const CompareChart = ({
    chartData,
    handleShowPlan,
    promoCode,
    phoneNumber
}: {
    chartData: ChartDataTypes[],
    handleShowPlan: Function,
    promoCode: string,
    phoneNumber: string
}) => {
    if (!chartData) return null;
    
    return (
        <>
            <ChartWrapper>
                <ResponsiveContainer>
                    <BarChart
                        data={chartData}
                        margin={{top: 30, bottom: 30, left: -36}}
                    >
                        <XAxis dataKey="id" stroke="#bbb" tick={ <CustomizedTick /> } />
                        <YAxis stroke="#fff" tick={{fontSize:10, fill: '#bbb'}} tickFormatter={(tickItem) => {
                            return tickItem
                        }} />
                        <Tooltip
                            { ... tooltipStyles }
                            position={{ y: 150 }}
                            wrapperStyle={{ zIndex: 1 }}
                            content={ <CustomTooltip /> }
                        />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={80}
                            shape={BarWithTopBorder()}
                            label={ <CustomizedLabel /> }
                        />
                        {
                            chartData[0] &&
                            <ReferenceLine
                                y={chartData[0].value}
                                stroke="#F68D2E"
                                strokeDasharray="5 3"
                                strokeWidth={2}
                            />
                        }
                    </BarChart>
                </ResponsiveContainer>
            </ChartWrapper>
            <ViewMoreBtnRow>
                {
                    chartData.map((val, idx) => {
                        if (val && val.id !== '230') return <div key={idx} />
                        return (
                            <div key={idx}>
                                <a className="rev-btn btn orangeButton" style={{margin:'0 auto'}} href={`https://rates.cleanskyenergy.com:8443/rates/index?promocode=${promoCode}`}
                                    // onClick={(evt) => {
                                    //     evt.preventDefault();
                                    //     handleShowPlan(val.id);
                                    // }}
                                >
                                    <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                                        <g>
                                            <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                                        </g>
                                    </svg>
                                    <span className="cta_one_button_span">VIEW PLANS</span>
                                    <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                                        <g>
                                            <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                                        </g>
                                    </svg>
                                </a>
                                {/* <Button size="small" variant="contained" onClick={() => {
                                    handleShowPlan(val.id)
                                }}>View Plans</Button> */}
                            </div>
                        )}
                    )
                }
            </ViewMoreBtnRow>
            <ChartFooter>Last scan date: {moment().isBetween(moment('00:00am', 'hh:mma'), moment('01:00am', 'hh:mma')) ? moment().subtract(1, 'days').format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}</ChartFooter>
        </>
    )
}

const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height, id } = props;

        let isHost = false;
        if (hostRep && hostRep.providerId === id) isHost = true;

		return (
			<g className={`bar-cell ${isHost ? 'special' : ''}`}>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" />
				<rect x={x} y={y} width={width} height={3} stroke="none" className="bar-top" rx="2" ry="2" />
			</g>
		)
	}
}

class CustomTooltip extends PureComponent {
    render() {
        const { active, payload }: Readonly<any> = this.props;
        
        if (!active || !payload || !payload[0]) return null;
        const { label, value } = payload[0].payload;
        
        return (
            <ChartTooltips>
                <h6>{label}</h6>
                <p>{value}¢</p>
            </ChartTooltips>
        )
    }
}

class CustomizedTick extends PureComponent {
    render() {
        const { x, y, payload, width, visibleTicksCount }: Readonly<any> = this.props;
        const maxWidth = 125;
        const _width = width / visibleTicksCount > maxWidth ? maxWidth : width / visibleTicksCount;
        if (payload.value === 0 || payload.value === 'auto') return null;
        return (
            <g transform={`translate(${x},${y})`}>
                <image
                    x={_width / 2 * -1}
                    y={0}
                    xlinkHref={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${payload.value}`}
                    width={_width}
                />
            </g>
        )
    }
}

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, value, width }: Readonly<any> = this.props;
        return (
            <text
                x={x + width / 2}
                y={y}
                dy={-8}
                fill='#3a3a3a'
                fontSize={width / 4}
                fontWeight="700"
                textAnchor="middle"
            >
                {value}¢
            </text>
        )
    }
}