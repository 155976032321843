import { PureComponent, useEffect, useState } from "react";
import { ChartFooter } from "./styles/common.styled";
import {
    BarChart, Bar, XAxis, CartesianGrid,
    ResponsiveContainer, ReferenceLine, YAxis
} from 'recharts';
import { ChartDataTypes } from "../types";
import moment from "moment";
import { getHeroCompare } from "../fetch/fetch";

export const HeroChart = () => {
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);

    useEffect(() => {
        getHeroCompare().then(res => {
            if (res && parseInt(res.status) === 1) {
                const { cleansky_floor_price, total_average_price } = res.response;

                setChartData([{
                    id: '0',
                    value: cleansky_floor_price ? parseFloat(cleansky_floor_price) : 0,
                    label: 'Clean Sky Energy'
                }, {
                    id: '1',
                    value: total_average_price ? parseFloat(total_average_price) : 0,
                    label: 'Average'
                }
                ])
            }
        })
    }, [])

    if (!chartData) return null;
    
    return (
        <>
            <div className="hero-chart">
                <ResponsiveContainer>
                    <BarChart
                        data={chartData}
                        margin={{top: 30, bottom: 20}}

                    >
                        <XAxis dataKey="id" stroke="#bbb" tick={ <CustomizedTick /> } />
                        <YAxis
                            hide={true}
                            domain={['dataMin -1', 'dataMax']}
                        />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={80}
                            shape={BarWithTopBorder()}
                            animationDuration={2000}
                            label={ <CustomizedLabel /> }
                        />
                        {
                            chartData[0] &&
                            <ReferenceLine
                                y={chartData[0].value}
                                stroke="#F68D2E"
                                strokeDasharray="5 3"
                                strokeWidth={2}
                            />
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <ChartFooter>
                <p>Market average rate based on 24 month term at 2,000 kWh per month in Centerpoint TDU</p>
                <p style={{marginBottom:0}}>Last scan date: {moment().isBetween(moment('00:00am', 'hh:mma'), moment('01:00am', 'hh:mma')) ? moment().subtract(1, 'days').format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}</p>
            </ChartFooter>
        </>
    )
}

const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height, id } = props;

        let isHost = id === '0' ? true : false;
		return (
			<g className={`bar-cell ${isHost ? 'special' : ''}`}>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" />
				<rect x={x} y={y} width={width} height={3} stroke="none" className="bar-top" rx="2" ry="2" />
			</g>
		)
	}
}

class CustomizedTick extends PureComponent {
    render() {
        const { x, y, payload, width, visibleTicksCount }: Readonly<any> = this.props;
        const maxWidth = 125;
        const _width = width / visibleTicksCount > maxWidth ? maxWidth : width / visibleTicksCount;
        if (payload.value === 0 || payload.value === 'auto') return null;
        
        return (
            <g transform={`translate(${x},${y})`}>
                {
                    payload.value === '0' ? 
                    <image
                        x={_width / 2 * -1}
                        y={0}
                        xlinkHref={`https://www.powerlego.com/ApiGateway/v2/logo/provider/230`}
                        width={_width}
                    /> :
                    <text
                        x={-20}
                        y={34}
                        fill='#666'
                        fontSize={14}
                    >Average</text>
                }
            </g>
        )
    }
}

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, value, width }: Readonly<any> = this.props;
        return (
            <text
                x={x + width / 2}
                y={y}
                dy={-8}
                fill='#3a3a3a'
                fontSize={width / 5}
                fontWeight="700"
                textAnchor="middle"
            >
                {value}¢
            </text>
        )
    }
}