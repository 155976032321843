import { styled } from '@mui/system';

export const SectionWrapper = styled('div')`
    text-align: center;
    padding: 12px 0 44px;

    p {
        font-size: 1.1rem;
        margin: 0;
    }

    @media (max-width: 991px) {
        p {
            text-align: center;
        }
    }

    @media (max-width: 600px) {
        margin-top: 64px;
    }
`