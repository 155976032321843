import { useEffect, useState } from "react";
import SavingSection from "./components/SavingSectionEs";
import { useSearchParams } from "react-router-dom";
import { formatPhoneNumber } from './common';
import { Header } from "./components/Header";

const Homepage = () => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ promoCode, setPromoCode ] = useState(searchParams.get('promo_code') || '');
    const [ phoneNumber, setPhoneNumber ] = useState(searchParams.get('phone') || '');

    return (
        <>
            <Header />
            <SavingSection />
            <div className="content_box half_row">
                <div className="content_items_container blueBack curve_right">
                    <div className="large_text_area">
                        <h2>¿Por qué elegir CleanSky como su proveedor de energía?</h2>
                        <p>Muy pocas veces la gente piensa de dónde proviene la energía que usa. Sin embargo, en CleanSky Energy nos preocupamos seriamente por la energía limpia para poder disminuir las consecuencias de la contaminación en la salud y en las vidas humanas a nivel global. Nos esforzamos por ofrecer los mejores planes, que combinan buen precio, cero emisiones, y protección de la salud de las personas. En pocas palabras, limpiamos el sector de energía con planes de energía totalmente transparentes.</p>
                    </div>
                </div>
                <div className="content_items_container none curve_none">
                    <ul className="icon_row icon_row_vertical left">
                        <li className="main_menu_item">
                            <img src="https://cleanskyenergy.com/wp-content/uploads/2020/07/earth.png" alt="Sustainable Earth graphic" />
                            <div className="ir_content_container">
                                <h4>Energía renovable al 100%</h4>
                                <p>Renueve su plan de energía y ayude a reducir su impacto al medio ambiente con uno de nuestros planes de energía 100% renovable.</p>
                            </div>
                        </li>
                        <li className="main_menu_item">
                            <img src="https://cleanskyenergy.com/wp-content/uploads/2020/07/money.png" alt="Money graphic" />
                            <div className="ir_content_container">	
                                <h4>Competitive Pricing</h4>
								<p>¡Limpia tu factura de la luz! Continúe y compare precios... CleanSky Energy se complace en ofrecer algunos de los mejores precios, independientemente de la fuente de energía. Simplemente no se demore, ya que el evento de limpieza de energía estacional no durará para siempre.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="content_box curved_strip">
                <div className="content_items_container none curve_none">
                    <div className="large_text_area"><h2 style={{textAlign: 'center'}}>LLÁMENOS para obtener la mejor tarifa, <br />y descubra lo asequible que puede ser la energía limpia.</h2>
                    {
                        phoneNumber &&
                        <h1 style={{textAlign: 'center'}}><a style={{color:'#f15536', fontSize:'100%'}} href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a></h1>
                    }
                    </div>
                </div>
                <div className="btn_postion_div align_center">
                    <div className="btn_container">
                        <a className="rev-btn btn orangeButton" href={`https://rates.cleanskyenergy.com:8443/rates/index?promocode=${promoCode}`}>
                            <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                                <g>
                                    <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                                </g>
                            </svg>
                            <span className="cta_one_button_span">Compre ahora</span>
                            <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                                <g>
                                    <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homepage