import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Footer } from './components/Footer';
import Homepage from './homepage';
import HomepageEs from './homepageEs';
import Compare from './compare';
import { PageWrapper, PageMain } from './components/styles/common.styled';
import './App.css';
import './styles.css';

declare module '@mui/material/Button/Button' {
	interface ButtonPropsVariantOverrides {
		'contained-secondary': true;
	}
}

const globalTheme = createTheme({
	palette: {
		primary: {
			main: '#F68D2E',
			dark: '#F68D2E',
			light: '#FFC72C'
		},
		secondary: {
			main: '#FFAF19',
			dark: '#071D49',
			light: '#FFAF19'
		}
	}
})

const theme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: 4,
					'& input': {
						'&:-webkit-autofill': {
							transitionDelay: '9999s',
							transitionProperty: 'background-color, color'
						}
					},
					'& .MuiFilledInput-root': {
						borderRadius: 4,
						border: '2px solid #FFAF19'
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					zIndex: 9999
				}
			}
		},
		MuiModal: {
			styleOverrides: {
				root: {
					zIndex: 10000
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 6,
					fontFamily: 'Roboto',
					color: '#fff',
					boxShadow: 'none',
					textTransform: 'inherit',
					'&:hover': {
						backgroundColor: '#f15536',
						boxShadow: 'none'
					}
				},
				sizeLarge: {
					fontSize: 17,
					padding: '12px 40px'
				},
				sizeMedium: {
					padding: '8px 20px'
				}
			},
			variants: [{
				props: { variant: 'contained' },
				style: {
					backgroundColor: '#FFAF19'
					// '&:hover': {
					// 	backgroundColor: '#f15536'
					// }
				}
			}, {
				props: { variant: 'outlined' },
				style: {
					boxShadow: 'none',
					backgroundColor: '#fff',
					border: `2px solid ${globalTheme.palette.secondary.main}`,
					color: globalTheme.palette.secondary.main,
					'&:hover': {
						color: '#fff'
					}
				}
			}, {
				props: { variant: 'contained-secondary' },
				style: {
					backgroundColor: globalTheme.palette.secondary.main,
					boxShadow: `0px 0px 0px 2px ${globalTheme.palette.secondary.main} inset`,
					color: '#fff'
				}
			}, {
				props: { variant: 'text' },
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					padding: 0
				}
			}]
		}
	}
}, globalTheme);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<PageMain>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage />} />
							<Route path="/es" element={<HomepageEs />} />
							<Route path="/compare" element={<Compare />} />
						</Routes>
					</BrowserRouter>
				</PageMain>
				<Footer />
			</PageWrapper>
		</ThemeProvider>
	);
}

export default App;
