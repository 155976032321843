import { styled } from '@mui/system';

export const StyledHeader = styled('header')`
    background-color: #eff7fa;
`

export const Logo = styled('a')`
    text-decoration: none;
    color: #666;
    font-size: 12px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    
    img {
        height: 32px;
    }

    @media (max-width: 991px) {
        padding: 10px 0;

        img {
            height: 41px;
        }
    }
`

export const PageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
        font-size: 3rem;
        line-height: 1.2;
        color: #1C4A79;
    }

    @media (max-width: 991px) {
        h3 {
            font-size: 2rem;
            text-align: center;
        }
    }
`

export const PageMain = styled('div')`
    flex: 1;
`

export const ChartWrapper = styled('div')`
    height: 400px;
    width: 100%;
    margin: 24px 0;

    @media (max-width: 991px) {
        height: 320px;
    }

    @media (max-width: 768px) {
        height: 260px;
    }
`

export const StyledFooter = styled('div')`
    background-color: #eff7fa;
    font-size: 14px;
    padding: 45px 0;
    margin-top: 44px;
`

export const ChartTooltips = styled('div')`
    background: #fff;
    border: 1px solid #FFAF19;
    border-radius: 8px;
    padding: 12px 16px;
    text-align: left;

    h6 {
        font-size: 12px;
        font-weight: 700;
        color: #3a3a3a;
        margin: 0 0 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
    }

    p {
        margin: 0;
    }
`

export const ViewMoreBtnRow = styled('div')`
    display: flex;
    padding-left: 2%;

    & > div {
        flex: 1;
        text-align: center;
    }

    @media (max-width: 768px) {
        padding-left: 4%;

        button {
            font-size: 11px;
            padding: 2px 6px;
        }
    }

    @media (max-width: 650px) {
        a.btn {
            padding: 8px 12px;
        }

        a.btn span {
            font-size: 10px;
        }
    }
`

export const HeaderMenu = styled('div')`
    display: flex;
    align-items: center;

    a.btn {
        margin-left: 12px;
    }

    a.orangeButton {
        zoom: 1.1;
    }

    @media (max-width: 991px) {
        display: ${({ active }: { active: string }) => (active && active === 'true') ? 'flex' : 'none'};
        position: fixed;
        top: 52px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.375rem 0px 0.625rem 3.5rem;
        
        button {
            margin-bottom: 1.375rem;
            font-size: 1.25rem;
            padding: 0.75rem 2.125rem;
        }

        a.btn {
            margin-left: 0;
            margin-top: 16px;
        }
    }
`

export const HeaderMenuItem = styled('a')`
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    font-size: 1vw;
    color: #33302C;
    padding: 10px 1vw;
    transition: all 0.25s ease 0s;
    margin-right: 1vw;

    :hover {
        color: #f15536;
    }


    @media (max-width: 991px) {
        font-size: 1.25rem;

        &:last-of-type {
            margin-bottom: 2.75rem;
        }
    }
`

export const StyledHeaderInner = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    button {
        box-shadow: none;
        margin-right: 1rem;

        :last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 991px) {
        width: 90%;
    }
`

export const EntrySection = styled('div')`
    background-color: #fff;
    padding-left: 10%;

    @media (max-width: 600px) {
        padding: 0 5%;
        margin-top: 44px;
    }
`

export const MenuToggle = styled('button')`
    cursor: pointer;
    border: 0 none;
    mask-image: ${({ active }: { active: string }) => (active && active === 'true') ? 'url("/images/X.svg")' : 'url("/images/Menu.svg")'};
    mask-position: 0 0;
    mask-repeat: no-repeat;
    background-color: #888;
    width: 24px;
    height: 24px;
    mask-size: cover;
    display: none;
    margin-right: 0 !important;

    @media (max-width: 991px) {
        display: inline-block;
    }
`

export const ModalBodyStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 650,
        xs: '85%'
    },
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
};

export const ChartFooter = styled('div')`
    margin-top: 24px;
    color: #999;
    font-size: 13px;
    text-align: center;

    p {
        font-size: 11px;
        color: #999;
    }
`

export const ModalCloseBtn = styled('button')`
    display: inline-block;
    width: 32px;
    height: 32px;
    mask: url('/images/X.svg') 0 0 no-repeat;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: 0 none;
    cursor: pointer;
`

export const SavingRow = styled('div')`
    margin: 4px 0;

    span {
        color: ${({ theme }) => theme.palette.primary.main};
        font-size: 32px;
        font-weight: 700;
        margin-left: 12px;
    }

    @media (max-width: 768px) {
        text-align: left;

        span {
            font-size: 24px;
            margin-left: 0;
        }
    }
`

export const CircleImg = styled('div')`
    left: 0;
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: ${({ imgsrc }: { imgsrc: string }) => `url(${imgsrc})`};
    position: relative;
    clip-path: ellipse(75vw 60vw at 75vw 32vw);

    @media (max-width: 768px) {
        padding-top: 60%;
        margin: 32px auto 0;
        clip-path: none;
        border-radius: 12px;
    }
`

export const FooterCopyright = styled('div')`
    background-color: #eff7fa;
    padding: 24px 0;
    font-size: .8vw;
    color: #253f66;

    a {
        text-decoration: none;
        margin: 0 6px;
    }

    a:first-child {
        margin-left: 0;
    }

    @media (max-width: 600px) {
        font-size: 1vh;
    }
`

export const FooterLinksCol = styled('div')`
    display: flex;
    flex-direction: column;

    span {
        margin: 10px 0;
        font-size: 1.15vw;
        color: #253f66;
    }

    a {
        text-decoration: none;
        margin: 10px 0;
        font-size: 1.15vw;
        color: #253f66;

        :first-child {
            font-weight: 700;
        }

        &:hover {
            color: #f15536;
        }
    }

    @media (max-width: 600px) {
        span, a {
            font-size: 1.8vh;
        }
    }
`