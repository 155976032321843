import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
    Button, Container, TextField, Grid, MenuItem,
    InputAdornment, ButtonGroup
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { SectionWrapper } from "./styles/saving-section.styled";
import { CompareChart } from "./CompareChart";
import { fetchTerms, fetchUtility, fetchPlan } from "../fetch/fetch";
import ReactLoading from "react-loading";
import { REPs } from "../config";
import { PlanTypes, ChartDataTypes, UtilityTypes } from "../types";
import NumberFormat from 'react-number-format';
import { PlanModal } from "./PlanModal";
import { RollingNumber } from "./RollingNumber";
import { Box } from "@mui/system";
import { withSnackbar } from './SnackbarHOC';
import { formatPhoneNumber } from '../common';
import { HeroChart } from "./HeroChart";

const SavingSection = ({
    snackbarShowMessage
}: {
    snackbarShowMessage?: Function;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ promoCode, setPromoCode ] = useState(searchParams.get('promo_code') || '');
    const [ phoneNumber, setPhoneNumber ] = useState(searchParams.get('phone') || '');

    const [ zipcode, setZipcode ] = useState('');
    const [ selectedTerm, setSelectedTerm ] = useState('');
    const [ utilities, setUtilities ] = useState<UtilityTypes[]>([]);
    const [ selectedUtility, setSelectedUtility ] = useState('');
    const [ termOptions, setTermOptions ]= useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ avgMonthlyUse, setAvgMonthlyUse ] = useState('1000');
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ oneRepPlanList, setOneRepPlanList ] = useState<PlanTypes[]>([]);
    const [ plans, setPlans ] = useState<{[key: string]: PlanTypes[]}|undefined>(undefined);
    const mainRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (zipcode.length === 5 && !loading) {
            setLoading(true);
            setSelectedUtility('');
            setUtilities([]);
            setSelectedTerm('');
            setChartData([]);
            getUtility();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    const getUtility = () => {
        fetchUtility(zipcode).then(res => {
            if (res && res[0] && res[0].utility_code) {
                setUtilities(res);

                if (res[0] && res[0].utility_code) {
                    setSelectedUtility(res[0].utility_name);
                }
            }
            else {
                snackbarShowMessage?.(res.message, 'error');
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        const utilityCode = findUtilityCode();
        
        if (utilityCode) {
            setSelectedTerm('');
            
            fetchTerms(utilityCode).then(res => {
                if (res && res.code === 1) {
                    let terms = res.response;
                    
                    terms = terms.filter((val: string) => val === '12' || val === '24');

                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1;
                        if (parseInt(a) > parseInt(b)) return 1;
                        return 0;
                    });
                    setTermOptions(terms);
                    if (terms.length > 0) {
                        if (terms.indexOf('12') >= 0) {
                            setSelectedTerm('12');
                        }
                        else {
                            setSelectedTerm(terms[0]);
                        }
                    }
                }
                else {
                    snackbarShowMessage?.(res.msg, 'error');
                    setLoading(false);
                }
            })
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUtility])

    useEffect(() => {
        if (selectedTerm && selectedUtility) {
            if (utilities.length > 1 && chartData.length === 0) {
                setLoading(false);
            }
            else {
                getPlan();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTerm, avgMonthlyUse])

    const handleScroll = () => {
        if (mainRef && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const findUtilityCode = () => {
        if (!selectedUtility) return '';

        return utilities.filter((val) => val.utility_name === selectedUtility)[0].utility_code;
    }

    const getPlan = () => {
        const usages = new Array(12).fill(parseInt(avgMonthlyUse));

        const utilityCode = findUtilityCode();

        setLoading(true);
        fetchPlan(
            parseInt(selectedTerm),
            utilityCode,
            usages
        ).then(res => {
            setLoading(false);

            let data = [];

            if (res && res.code === 1) {
                const { response } = res;

                let allPlans: {[key: string]: PlanTypes[]} = {};

                for (let i in response) {
                    if (i === '230') {
                        allPlans[i] = response[i].filter((val: PlanTypes) => val.plan_name === 'Clean Up 12' || val.plan_name === 'Clean Up 24');
                    }
                    else {
                        allPlans[i] = response[i];
                    }
                }

                for (let i in allPlans) {
                    const plans = allPlans[i];

                    let totalRate = 0;
                    plans.forEach((val: PlanTypes, idx: number) => {
                        const { total_rate } = val;
                        totalRate += total_rate;
                    });
                    
                    data.push({
                        id: i,
                        value: Math.round(totalRate / plans.length * 10) / 10,
                        label: REPs.filter(val => val.providerId === i)[0].providerName
                    });
                }
                setPlans(allPlans);
                handleSetChartData(data);
                setTimeout(() => {
                    handleScroll();
                }, 200);
            }
            else {
                snackbarShowMessage?.(res.msg, 'error');
            }
        })
    }

    const handleSetChartData = (data: ChartDataTypes[]) => {
        let arr = data;

        arr.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });

        let _chartData = [];
        const hostRep = REPs.filter(val => val.host === true);
        const hostData = arr.filter(val => val.id === hostRep[0].providerId);
        
        if (hostData[0]) {
            _chartData.push(hostData[0]);
        }

        const hostDataIdx = arr.findIndex(val => val.id === hostData[0].id);
        arr.splice(hostDataIdx, 1);
        _chartData = _chartData.concat(arr.slice(-3));
        
        setChartData(_chartData);
    }

    const handleChangeZipcode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNums = evt.target.value.replace(/[^0-9]/g, '');
        setZipcode(onlyNums);
    }

    let energySavings = 0;
    if (chartData && chartData.length > 0) {
        const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
        energySavings = rateDiff * parseFloat(avgMonthlyUse) * parseInt(selectedTerm) / 100;
    }

    return (
        <>
            <div className="content_box full_row">
                <div className="content_items_container none curve_none">
                    <div className="cta_section_two">
                        {/* <img className="cta_two_background_image" src="https://cleanskyenergy.com/wp-content/uploads/2022/09/AdobeStock_193838862-scaled-e1662407821200.jpeg" alt="" /> */}
                        <div className="cta_two_content">
                            <h1>¡El EVENTO DE RENOVACIÓN DE ENERGÍA ya está aquí!</h1>
                            <p>
                                Si ha estado esperando el “momento adecuado” para cambiar a energía renovable, la espera ha terminado. <br />
                                ¡Asegúrese nuestras tarifas más bajas hoy mismo!
                                <img src="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=signup&pixid=87989dbb-a354-4130-bea2-18f880e4cad6" width="1" height="1" alt="" />
                                <br />
                                <span style={{fontSize:'9px'}}>Oferta por tiempo limitado, la promoción finaliza el 10/31/2022</span>
                            </p>
                            <Grid container spacing={3} sx={{mt:4,textAlign:'center'}}>
                                <Grid item xs={11} md={5}>
                                    <TextField
                                        label="Su código postal"
                                        value={zipcode}
                                        onChange={handleChangeZipcode}
                                        inputProps={{ maxLength: 5 }}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: loading ? <InputAdornment position="end"><ReactLoading type="bubbles" color="#bbb" width={36} height={36} /></InputAdornment> : null
                                        }}
                                        fullWidth
                                        disabled={loading}
                                        variant="filled"
                                    />
                                </Grid>
                                {
                                    (utilities && utilities.length > 1) &&
                                    <Grid item xs={11} md={5}>
                                        <Select
                                            labelId="utility-label"
                                            id="utility"
                                            value={selectedUtility}
                                            onChange={(evt: SelectChangeEvent) => {
                                                setSelectedUtility(evt.target.value as string);
                                            }}
                                            disabled={loading}
                                            fullWidth
                                            sx={{textAlign:'left'}}
                                        >
                                            {
                                                utilities.map((val: any, idx) => <MenuItem value={val.utility_name} key={idx}>{val.utility_name}</MenuItem>)
                                            }
                                        </Select>
                                    </Grid>
                                }
                            </Grid>
                            {
                                (utilities && utilities.length > 1) &&
                                <div className="cta_btn btn_postion_div">
                                    <div className="btn_container" style={{marginTop:12}}>
                                        <a className="rev-btn btn orangeButton" href="###" onClick={(evt) => {
                                            evt.preventDefault();
                                            if (loading || !zipcode || !selectedUtility) {
                                                return;
                                            }
                                            getPlan();
                                        }}>
                                            <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                                                <g>
                                                    <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                                                </g>
                                            </svg>
                                            <span className="cta_one_button_span">Continuar</span>
                                            <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                                                <g>
                                                    <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            }
                            {/* <Box sx={{mt:4,textAlign:{xs:'center',sm:'left'}}}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={getPlan}
                                    disabled={loading || !zipcode || !selectedUtility}
                                >Continue</Button>
                            </Box> */}
                        </div>
                        <div className="hero-highlight">
                            <div className="hero-chart-wrapper">
                                <h4>See How Our Rates Compare!</h4>
                                <HeroChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content_box full_row">
                <div className="content_items_container shadow_curve_true none  curve_none">
                    <div className="large_text_area">
                        <h2 style={{textAlign:'center'}}>LLAME AHORA para renovar su plan de energía</h2>
                        <h1 style={{textAlign:'center'}}><a style={{color:'#f15536', fontSize:'100%'}} href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a></h1>
                        <p style={{textAlign:'center'}}>¡CleanSky Energy ofrece a los tejanos un poco de alivio después de un verano frenético con sus mejores tarifas de la temporada!</p>
                    </div>
                </div>
            </div>
            <div className="shadow_curve none">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 407.5 36">
                    <path d="M407.5,0l0,10.4C234.9,52.7,90.7,35.4,0,10.4V0H407.5z"></path>
                </svg>
            </div>
            <SectionWrapper>
                <Container ref={mainRef}>
                    {
                        (selectedTerm && chartData.length > 0) &&
                        <>
                            <h2 style={{marginBottom:12}}>Estimated Total Savings = <RollingNumber data={energySavings} styles={{color:'#F68D2E',fontWeight:700,fontSize:'inherit'}} /></h2>
                            <Grid container flexDirection={{xs:'column-reverse',sm:'row'}}>
                                <Grid container item xs={12} sm={7} alignItems="center" justifyContent={{xs:'center',sm:'flex-start'}} sx={{marginTop:{xs:3,sm:0}}}>
                                    <span style={{marginRight:12}}>Avg. Monthly Use:</span>
                                    <ButtonGroup sx={{marginTop:{xs:2,sm:0}}}>
                                        {
                                            ['500', '1000', '2000'].map((val, idx) => 
                                            <Button variant={avgMonthlyUse === val ? 'contained-secondary' : 'outlined'} key={idx} onClick={() => {
                                                setAvgMonthlyUse(val);
                                            }}>{`${val} kWh`}</Button>)
                                        }
                                    </ButtonGroup>
                                    {/* <SavingRow>Energy Savings: <NumberFormat value={energySavings} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></SavingRow> */}
                                </Grid>
                                <Grid container item xs={12} sm={5} alignItems="center" justifyContent="flex-end">
                                    <div>
                                        <span style={{marginRight:12}}>Term:</span>
                                        <Select
                                            labelId="term-select-label"
                                            id="term-select"
                                            value={selectedTerm}
                                            onChange={(evt: SelectChangeEvent) => {
                                                setSelectedTerm(evt.target.value as string);
                                            }}
                                            disabled={loading}
                                        >
                                            {
                                                termOptions.map((val, idx) => <MenuItem value={val} key={idx}>{`${val} Months`}</MenuItem>)
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <Grid container justifyContent="center" alignItems="center" sx={{mt:2,mb:4}}>
                                <span style={{marginRight:12}}>Avg. Monthly Use:</span>
                                <ButtonGroup>
                                    {
                                        ['500', '1000', '2000'].map((val, idx) => 
                                        <Button variant={avgMonthlyUse === val ? 'contained-secondary' : 'outlined'} key={idx} onClick={() => {
                                            setAvgMonthlyUse(val);
                                        }}>{`${val} kWh`}</Button>)
                                    }
                                </ButtonGroup>
                            </Grid> */}
                            <CompareChart
                                chartData={chartData}
                                handleShowPlan={(id: string) => {
                                    setShowModal(true);
                                    if (plans && plans[id]) {
                                        setOneRepPlanList(plans[id]);
                                    }
                                }}
                                promoCode={promoCode}
                                phoneNumber={phoneNumber}
                            />
                            <Grid container spacing={4} alignItems="center" justifyContent="center" style={{marginTop:24}}>
                                <Grid item>For a personalized Savings Calculator based on your actual consumption, your choice of term and in your area <a href="javascript:;" style={{color:'#f15536',textDecoration:'none',fontSize:'inherit'}} onClick={() => {
                                    let quary = '';
                                    if (location && location.search) {
                                        const { search } = location;
                                        
                                        if (search.split('user=')[1] === 'eiq') {
                                            quary = '?user=eiq'
                                        }
                                    }
                                    navigate(`/compare${quary}/${promoCode ? `?promo_code=${promoCode}` : ''}${phoneNumber ? `&phone=${phoneNumber}` : ''}`);
                                }}>click here</a></Grid>
                            </Grid>
                            <Grid>
                                <a className="rev-btn btn btn-lg orangeButton" href={`https://rates.cleanskyenergy.com:8443/rates/index?promocode=${promoCode}`} style={{margin:'24px auto'}}>
                                    <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                                        <g>
                                            <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                                        </g>
                                    </svg>
                                    <span className="cta_one_button_span">SHOP NOW</span>
                                    <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                                        <g>
                                            <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </Grid>
                        </>
                    }
                </Container>
                {/* <Box style={{backgroundColor:'#071D49'}} sx={{mt:8,pt:5,pb:5}}>
                    <Container>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <h2 style={{margin:0,color:'#fff'}}>Harness the Power of the Breeze</h2>
                            </Grid>
                            <Grid item xs={12} sm={6} textAlign={{xs:"center",sm:"right"}} sx={{marginTop:{xs:3,sm:0}}}>
                                <Button size="large" variant="contained" onClick={() => {
                                    window.location.href = "https://cleanskyenergy.com/residential-electricity/"
                                }}>LEARN MORE</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box> */}
                <PlanModal
                    show={showModal}
                    data={oneRepPlanList}
                    handleCloseModal={() => { setShowModal(false); }}
                />
            </SectionWrapper>
        </>
    )
}

export default withSnackbar(SavingSection)