import { useState } from "react";
import {
    StyledHeader, Logo, StyledHeaderInner,
    HeaderMenu, HeaderMenuItem, MenuToggle
} from "./styles/common.styled";
// import { Button } from '@mui/material';

export const Header = () => {
    const [ showMenu, setShowMenu ] = useState(false);

    let isEs = false,
        isHomepage = false,
        langUrl = window.location.href;
        
    if (window.location.pathname) {
        const { pathname } = window.location;

        isEs = pathname.toLocaleLowerCase().indexOf('/es') === 0 ? true : false;
        isHomepage = (pathname === '/' || pathname === '/es' || pathname === '/es/') ? true : false;

        if (isEs) {
            langUrl = `${window.location.origin}/${window.location.search}`;
        }
        else {
            langUrl = `${window.location.origin}/es/${window.location.search}`;
        }
    }

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <Logo href="https://cleanskyenergy.com/">
                    <img src="/images/logo.svg" width="200" alt="" style={{marginRight:20}} />
                    <span>Side-by-side</span>
                </Logo>
                <MenuToggle
                    active={`${showMenu}`}
                    onClick={() => {
                        if (showMenu) {
                            document.body.style.overflow = 'unset'
                        }
                        else {
                            document.body.style.overflow = 'hidden'
                        }
                        setShowMenu(!showMenu)
                    }}
                />
                <HeaderMenu active={`${showMenu}`}>
                    <HeaderMenuItem href="https://cleanskyenergy.com/our-story/">ABOUT US</HeaderMenuItem>
                    <HeaderMenuItem href={isEs ? "https://cleanskyenergy.com/es/contactenos/" : "https://cleanskyenergy.com/contact-us/"}>CONTACT US</HeaderMenuItem>
                    <a className="rev-btn btn orangeButton" href="https://myaccount.cleanskyenergy.com:8443/#/login">
                        <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                            <g>
                                <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                            </g>
                        </svg>
                        <span className="cta_one_button_span">LOGIN</span>
                        <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                            <g>
                                <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                            </g>
                        </svg>
                    </a>
                    {
                        isHomepage &&
                        <a className="btn orangeButtonHollow translation_link" href={langUrl}>
                            <svg className="up-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 70.3 70.3">
                                <g>
                                    <path className="st0" d="M7.2,70.3C3.2,70.3,0,67,0,63V43C0,19.3,19.3,0,43,0h20c4,0,7.2,3.2,7.2,7.2S67,14.5,63,14.5H43 c-15.8,0-28.6,12.8-28.6,28.6v20C14.5,67,11.2,70.3,7.2,70.3z"></path>
                                </g>
                            </svg>
                            <span className="cta_one_button_span">{isEs ? 'ENGLISH' : 'Español'}</span>
                            <svg className="down-swoosh" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.3 70.3">
                                <g>
                                    <path className="st0" d="M63,0c4,0,7.2,3.2,7.2,7.2v20c0,23.7-19.3,43-43,43h-20C3.2,70.3,0,67,0,63s3.2-7.2,7.2-7.2h20 C43,55.8,55.8,43,55.8,27.2v-20C55.8,3.2,59.1,0,63,0z"></path>
                                </g>
                            </svg>
                        </a>
                    }
                    {/* <Button size="medium" variant="contained-secondary" onClick={() => {
                        window.location.href = "https://myaccount.cleanskyenergy.com:8443/#/login"
                    }}>LOGIN</Button> */}
                </HeaderMenu>
            </StyledHeaderInner>
        </StyledHeader>
    )
}